import React from "react"
import { Link } from 'gatsby';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/css/live.css"
import HomeBannerImage from '../../assets/images/home/landing-row-bg.png'
import liveEng from "../../assets/images/live/en.png"
import liveAr from "../../assets/images/live/ar.png"

const LivePage = () => (
  <Layout>
    <SEO title="Home" />
    <main>
        <div className="uk-cover-container live-page relative" data-uk-height-viewport="min-height: 750">
            <img src={HomeBannerImage} style={{transform: "translate(-50%,-50%)"}} alt="" data-uk-cover />
            <div className="uk-overlay uk-light uk-position-center">
              <div className="large-container uk-text-center ph5-ns pb5">
                <div  style={{padding: "20px"}}>
                  <h2 className="mb0">
                    Launch Videos
                  </h2>
                </div>
              </div>
              <div className="large-container padding-bottom-0 ph5-ns">
                <div className="w-100 flex items-start justify-between overflow-x-auto link-container">
                  <a className="w-50 w-20-ns min-width-250" data-uk-toggle="target: #live-arabic">
                    <img src={liveAr} alt=""/>
                    <br/>
                    <p className="uk-text-large uk-text-center uk-text-bold text-light">
                    Dubai TV
                    </p>
                  </a>
                  <a className="w-50 w-20-ns min-width-250" data-uk-toggle="target: #live-4">
                    <img src={liveAr} alt=""/>
                    <br/>
                    <p className="uk-text-large uk-text-center uk-text-bold text-light mb0">
                    Abu Dhabi TV 
                    </p>
                  </a>
                  <a className="w-50 w-20-ns min-width-250" data-uk-toggle="target: #live-english">
                    <img src={liveEng} alt=""/>
                    <br/>
                    <p className="uk-text-large uk-text-center uk-text-bold text-light">
                    Dubai One
                    </p>
                  </a>
                  <a className="w-50 w-20-ns min-width-250" data-uk-toggle="target: #live-no-commentary">
                    <img src={liveEng} alt=""/>
                    <br/>
                    <p className="uk-text-large uk-text-center uk-text-bold text-light mb0">
                    Without Commentary
                    </p>
                  </a>
                </div>
              {/* <div className="uk-margin flex flex-column flex-row-ns items-center hero-countdown-container">
                <div className="w-100 w-30-ns">
                    <p className="uk-text-lead uk-text-center text-highlight-brown  mb1">
                    COUNTDOWN TO LAUNCH
                    </p>
                    <p className="uk-text-meta uk-text-center mb1 mt0">
                    20th July, 2020
                    </p>
                </div>
                <div className="w-100 w-70-ns ph4-ns ph2">
                    <div className="uk-grid-small uk-child-width-auto justify-between" data-uk-grid uk-countdown="date: 2020-07-20T01:58:14+04:00">
                        <div className="flex flex-column flex-row-ns" style={{paddingRight: "0", paddingLeft: "0"}}>
                            <div className="uk-countdown-number uk-countdown-days mr1"></div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center text-highlight-brown uk-text-bold">Days</div>
                        </div>
                        <div className="uk-countdown-separator">|</div>
                        <div className="flex flex-column flex-row-ns" style={{paddingRight: "0", paddingLeft: "0"}}>
                            <div className="uk-countdown-number uk-countdown-hours mr1"></div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center text-highlight-brown uk-text-bold">Hours</div>
                        </div>
                        <div className="uk-countdown-separator">|</div>
                        <div className="flex flex-column flex-row-ns" style={{paddingRight: "0", paddingLeft: "0"}}>
                            <div className="uk-countdown-number uk-countdown-minutes mr1"></div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center text-highlight-brown uk-text-bold">Minutes</div>
                        </div>
                        <div className="uk-countdown-separator">|</div>
                        <div className="flex flex-column flex-row-ns" style={{paddingRight: "0", paddingLeft: "0"}}>
                            <div className="uk-countdown-number uk-countdown-seconds mr1"></div>
                            <div className="uk-countdown-label uk-margin-small uk-text-center text-highlight-brown uk-text-bold">Seconds</div>
                        </div>
                    </div>
                </div>          
            </div>
             */}
              </div>
           </div>

           <div className="absolute ml4 mb4 bottom-0 left-0">
              <Link to="/live">
                  <button className="uk-button uk-button-default" style={{ background: 'rgba(0,0,0,0.5)', color: '#fff' }}>
                      <span uk-icon="icon: triangle-right; ratio: 2" style={{ marginLeft: '-20px' }}></span>
                      <span className="f5 b">Watch Live</span>
                  </button>
              </Link>
          </div>
        </div>

    <div id="live-arabic" className="uk-flex-top" data-uk-modal>
      <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
          <button className="uk-modal-close-outside" type="button" data-uk-close></button>
          <iframe src="https://www.youtube.com/embed/OT1Ttffk7b4?playsinline=1" allowFullScreen="1" className="live-video-iframe" frameborder="0" data-uk-video data-uk-responsive></iframe>
          {/* <iframe src="https://www.youtube.com/embed/9css3XnyYJg" allowFullScreen="1" className="live-video-iframe" frameborder="0" data-uk-video data-uk-responsive></iframe> */}
      </div>
    </div>
    <div id="live-english" className="uk-flex-top" data-uk-modal>
      <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
          <button className="uk-modal-close-outside" type="button" data-uk-close></button>
          <iframe src="https://www.youtube.com/embed/Wmj5gIRDg6c?playsinline=1" allowFullScreen="1"  className="live-video-iframe" frameborder="0" data-uk-video data-uk-responsive></iframe>
      </div>
    </div>
    <div id="live-no-commentary" className="uk-flex-top" data-uk-modal>
      <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
          <button className="uk-modal-close-outside" type="button" data-uk-close></button>
          <iframe src="https://www.youtube.com/embed/RrIc7ZlwbgE?playsinline=1" allowFullScreen="1"  className="live-video-iframe" frameborder="0" data-uk-video data-uk-responsive></iframe>
      </div>
    </div>
    <div id="live-4" className="uk-flex-top" data-uk-modal>
      <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
          <button className="uk-modal-close-outside" type="button" data-uk-close></button>
          <iframe src="https://www.youtube.com/embed/eUPT-1I5Wdk?playsinline=1" allowFullScreen="1"  className="live-video-iframe" frameborder="0" data-uk-video data-uk-responsive></iframe>
      </div>
    </div>
    </main>
  </Layout>
)

export default LivePage